import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Resources from "./Resources"
import AddResource from "./AddResource";
import Error from "./Error";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Lato',
      'Roboto'
    ].join(','),
    fontSize: 13
  },  
});

function App() {
  return (
    <div className="App container">
      <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Resources />
          </Route>
          <Route path="/addResource">
            <AddResource />
          </Route>
          <Route path="/error">
            <Error/>
          </Route>
        </Switch>
      </Router>
      </ThemeProvider>
    </div>

  );
}

export default App;
