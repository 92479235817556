export default {
    state:{
        environment:"dev"
    },
    environments:{
        dev:{
            endpoints:{
                list:"https://vswoc6wugc.execute-api.us-east-2.amazonaws.com/web-resource-list",
                add:"https://vswoc6wugc.execute-api.us-east-2.amazonaws.com/web-resource-add",
                delete:"https://vswoc6wugc.execute-api.us-east-2.amazonaws.com/web-resource-delete"
            }
        },
        prod:{
            endpoints:{
                list:"https://9zq1h1sxnc.execute-api.us-east-2.amazonaws.com/web-resource-list",
                add:"https://9zq1h1sxnc.execute-api.us-east-2.amazonaws.com/web-resource-add",
                delete:"https://9zq1h1sxnc.execute-api.us-east-2.amazonaws.com/web-resource-delete"
            }
        }
    }
}