import React from "react";
import image from './access-denied.png';

function Error() {
    return (
        <div style={{backgroundColor:"white"}}> 
            <center>
                <img src={image} width={50} height={50} style={{paddingTop:30}}></img>
                <h1>Access denied</h1>
                <h2>You don´t have permissions to access</h2>
            </center>
        </div>
    )
}

export default Error;