import axios from 'axios';
import {
    getEnvironment
} from './environment';

var environment = getEnvironment();

export const getResources = async (token, prefix, bucket, searchTerms) => {
    return await axios.post(environment.list, {
            bucket: bucket,
            prefix: `${prefix}/resources/`,
            metadata: true,
            searchTerms: searchTerms ?? ''
        }, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        .then(res => res.data)
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const addResource = async (token, prefix, bucket, item) => {
    let file64 = await GetSelectedFileAsBase64EncodedString(item.file);
    return await axios.post(environment.add, {
            bucket: bucket,
            prefix: `${prefix}/resources/`, //`${prefix ? prefix: ""}`
            file: file64,
            fileName: item.file.name,
            title: item.title,
            description: item.description,
            tags: item.tags
        }, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        .then(res => res.data)
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const deleteResource = async (token, bucket, prefix, item) => {
    return await axios.post(environment.remove, {
            filename: item.Key.split('/').at(-1), // file name only
            prefix: `${prefix}/resources/`,
            bucket: bucket
        }, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        .then(res => res.data)
        .catch(err => {
            console.error(err);
            throw err;
        });
}

async function GetSelectedFileAsBase64EncodedString(file) {

    let base64EncodedFile = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            if (!reader.result)
                throw new Error("Couldn't base64 encode");

            resolve(reader.result.toString());
        }

        reader.onerror = error => reject(error);
    }).catch(e => {
        throw new Error(e)
    });

    return base64EncodedFile
}